<template>
  <div
    :class="[
      {
        'el-table': true,
        'el-table--search-assign': searchAssign,
        'el-table--editable': _editable,
        'el-table--collapsed': collapsed,
        'el-table--paginate': showPagination,
        'el-table--fit': fit,
        'el-table--striped': stripe,
        'el-table--border': border || isGroup,
        'el-table--hidden': isHidden,
        'el-table--group': isGroup,
        'el-table--fluid-height': maxHeight,
        'el-table--scrollable-x': layout.scrollX,
        'el-table--scrollable-y': layout.scrollY,
        'el-table--enable-row-hover': !store.states.isComplex,
        'el-table--enable-row-transition':
          (store.states.data || []).length !== 0 &&
          (store.states.data || []).length < 100,
      },
      tableSize ? `el-table--${tableSize}` : '',
    ]"
  >
    <!-- topWrapper -->
    <div v-if="showTopBar" ref="topWrapper" class="el-table__prepend-wrapper">
      <div
        :class="['el-table__prepend-inner', 'el-table__prepend-inner--left']"
      >
        <p v-for="(item, index) in arrayTitle.list" :key="`${index}_title`">
          <span
            class="prepend-left--title"
            :style="{ color: arrayTitle.color }"
          >
            {{ item }}
          </span>
        </p>
        <span v-if="legend" class="prepend-left--title">
          {{ legend }}
        </span>
        <span v-if="notice" class="prepend-left--notice">{{ notice }}</span>
        <slot name="prepend-left" />
      </div>
      <slot name="prepend-mid" />
      <div
        :class="['el-table__prepend-inner', 'el-table__prepend-inner--right']"
      >
        <span v-if="isDevelopment && programCode" class="program-code"
          >{{ programCode }} /
          {{ $store.getters["user/programEventCode"](programCode) }}</span
        >
        <div
          v-if="$scopedSlots.prepend"
          v-show="!collapsed"
          class="prepend-slot"
        >
          <slot :data="store.states.data" name="prepend" />
        </div>
        <span v-if="totalCount" class="total-count-text">{{ totalCount }}</span>
        <div v-if="searchAssign" class="button-group">
          <el-row :gutter="8">
            <el-col :span="24">
              <el-button
                v-if="!assignButtonHidden"
                :disabled="assignButtonDisabled"
                size="small"
                @click="assignedRow(data)"
                >{{ $tc("el.table.assignButtonText") /*입사발령*/ }}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <slot name="codeFilter"></slot>
        <div v-if="!copyCompanyHolidayButtonHidden.hidden">
          <el-row>
            <el-col>
              <el-select
                v-if="!copyCompanyHolidayButtonHidden"
                v-model="selectedCompanies"
                :disabled="copyCompanyHolidayDisabled"
                :placeholder="$tc('el.table.copyCompanyHolidayPlaceHolder')"
                multiple
                collapse-tags
                size="small"
                class="tableCompanies"
              >
                <el-option
                  v-for="(item, index) in filteredOptions"
                  :key="`${index}_option1`"
                  :value="item.companyId"
                  :disabled="item.companyId * 1 === companyOriginalId * 1"
                  :label="item.label"
                />
              </el-select>
            </el-col>
          </el-row>
        </div>
        <span v-if="programCode === 'HPE1900'">
          <el-select
            v-model="selectedCompanies"
            :disabled="copyCompanyHolidayDisabled"
            :placeholder="$tc('el.table.copyCompanyHolidayPlaceHolder')"
            multiple
            collapse-tags
            size="small"
            style="display: inline-block; width: 220px; margin-right: 5px"
            class="tableCompanies"
          >
            <el-option
              v-for="(item, index) in filteredOptions"
              :key="`${index}_option2`"
              :value="item.companyId"
              :label="item.label"
              :hidden="item.companyId === companyId"
            />
          </el-select>
          <el-button
            :disabled="data.length < 1"
            size="small"
            style="margin-right: 8px"
            @click="selectChangeHandler(selectedCompanies)"
            >{{
              $tc("el.table.selectedCompaniesSave") /*법인선택저장*/
            }}</el-button
          >
        </span>
        <span v-if="programCode === 'HPE1910'">
          <el-button
            :disabled="data.length < 1"
            size="small"
            @click="printCertificate(data)"
            >{{
              $tc("el.table.previewCertificate") /*증명서 미리보기*/
            }}</el-button
          >
          <el-button
            size="small"
            style="margin-right: 8px"
            @click="openCertificate('new')"
            >{{ $tc("el.table.newCertificate") /*신규*/ }}</el-button
          >
        </span>
        <div v-if="_editable" v-show="!collapsed" class="button-group">
          <slot name="pre-button-group"></slot>
          <el-button
            v-if="!copyCompanyHolidayButtonHidden"
            :disabled="
              copyCompanyHolidayDisabled || selectedCompanies.length === 0
            "
            size="small"
            @click="copyCompanyHolidayFn()"
            >{{ $tc("el.table.copyCompanyHoliday") /*타 법인 복사*/ }}
          </el-button>
          <el-button
            v-if="!copyYearHolidayButtonHidden"
            :disabled="copyPreviousYearHolidayDisabled"
            size="small"
            @click="copyPreviousYearHolidayFn()"
            >{{ $tc("el.table.copyPreviousYearHoliday") /*전년도자료복사*/ }}
          </el-button>
          <el-button
            v-if="!employeeButtonHidden"
            :disabled="employeeButtonDisabled"
            size="small"
            @click="handleButtonClick(employeeButtonOption, onEmployeeClick)"
            >{{ $tc("el.table.employeeButtonText") /*구성원 등록*/ }}
          </el-button>
          <el-button
            v-if="!cancelButtonHidden"
            :disabled="cancelButtonDisabled"
            size="small"
            @click="handleButtonClick(cancelButtonOption, onCancelClick)"
            >{{ $tc("el.table.cancelButtonText") /*취소*/ }}
          </el-button>
          <el-button
            v-if="!addButtonHidden"
            :disabled="addButtonDisabled"
            size="small"
            @click="handleButtonClick(addButtonOption, onAddClick)"
            >{{ $tc("el.table.addButtonText") /*추가*/ }}
          </el-button>
          <el-button
            v-if="!copyButtonHidden"
            :disabled="copyButtonDisabled"
            size="small"
            @click="handleButtonClick(copyButtonOption, onCopyClick)"
            >{{ $tc("el.table.copyButtonText") /*복사*/ }}
          </el-button>
          <el-button
            v-if="!removeButtonHidden"
            :disabled="removeButtonDisabled"
            type="danger"
            plain
            size="small"
            @click="handleButtonClick(removeButtonOption, onRemoveClick)"
            >{{ $tc("el.table.removeButtonText") /*삭제*/ }}
          </el-button>
          <el-button
            v-if="!saveButtonHidden"
            :disabled="saveButtonDisabled"
            type="primary"
            size="small"
            @click="handleButtonClick(saveButtonOption, onSaveClick)"
            >{{ $tc("el.table.saveButtonText") /*저장*/ }}
          </el-button>
          <el-button
            v-if="!downloadButtonHidden"
            :disabled="downloadButtonDisabled || !data || !data.length"
            size="small"
            @click="download"
            >{{ $tc("el.table.downloadButtonText") /*다운로드*/ }}
          </el-button>
          <el-upload
            v-if="!uploadButtonHidden"
            ref="upload"
            :disabled="uploadButtonDisabled"
            :auto-upload="false"
            :multiple="false"
            :show-file-list="false"
            :on-change="handleUpload"
            :on-remove="handleRemove"
            :before-upload="() => undefined"
            :on-preview="() => undefined"
            :on-success="() => undefined"
            :on-error="() => undefined"
            :on-exceed="() => undefined"
            :on-progress="() => undefined"
            :http-request="() => undefined"
            :action="'/'"
            style="margin-left: 8px; display: inline-block"
            name="uploadFile"
            list-type="text"
          >
            <el-button :disabled="uploadButtonDisabled" size="small"
              >{{ $tc("el.table.uploadButtonText") /*업로드*/ }}
            </el-button>
          </el-upload>
          <slot name="local-button-group"></slot>
        </div>
        <HAS1200
          v-if="findAssignDialogVisible"
          :value="assignSearchStr"
          @close="resetState"
          @change="setAssign"
        />
        <HPE1915
          v-if="newCertificateVisible"
          @close="newCertificateVisible = false"
        />
      </div>
    </div>
    <div
      v-loading="loading || pending"
      :style="tableStyle"
      class="table-body-wrapper"
      element-loading-background="transparent"
      @mouseleave="handleMouseLeave($event)"
    >
      <div ref="hiddenColumns" class="hidden-columns">
        <slot />
      </div>

      <!-- headerWrapper -->
      <div
        v-if="showHeader"
        ref="headerWrapper"
        v-mousewheel="handleHeaderFooterMousewheel"
        class="el-table__header-wrapper"
      >
        <table-header
          ref="tableHeader"
          :store="store"
          :border="border"
          :default-sort="defaultSort"
          :style="{
            width: layout.bodyWidth ? layout.bodyWidth + 'px' : '',
          }"
        />
      </div>
      <!-- bodyWrapper -->
      <div
        ref="bodyWrapper"
        :class="[
          layout.scrollX
            ? `is-scrolling-${scrollPosition}`
            : 'is-scrolling-none',
        ]"
        :style="[bodyHeight]"
        class="el-table__body-wrapper"
      >
        <span
          v-if="data.length && _editable"
          @focus="store.commit('setCurrentCell', null, null)"
          v-text="''"
        />
        <table-body
          :local="showLocalPagination"
          :page="page"
          :size="newPaginationSize"
          :context="context"
          :store="store"
          :stripe="stripe"
          :row-class-name="rowClassName"
          :row-style="rowStyle"
          :highlight="highlightCurrentRow"
          :style="{
            width: bodyWidth,
          }"
        />
        <span
          v-if="data.length && _editable"
          @focus="store.commit('setCurrentCell', null, null)"
          v-text="''"
        />
        <div
          v-if="!data || data.length === 0"
          ref="emptyBlock"
          :style="{
            width: bodyWidth,
          }"
          class="el-table__empty-block"
        >
          <transition name="el-zoom-in-top">
            <div
              v-if="isDetailTable && isMasking"
              class="el-table__detail-mask"
            >
              <span class="detail-mask__text">
                {{ t("el.table.selectToLookUpFirst") }}
              </span>
            </div>

            <span v-else class="el-table__empty-text">
              <slot name="empty">{{
                emptyText || t("el.table.emptyText")
              }}</slot>
            </span>
          </transition>
        </div>
        <div
          v-if="$slots.append"
          ref="appendWrapper"
          class="el-table__append-wrapper"
        >
          <slot name="append" />
        </div>
      </div>
      <!-- footerWrapper -->
      <div
        v-if="showSummary"
        v-show="data && data.length > 0"
        ref="footerWrapper"
        v-mousewheel="handleHeaderFooterMousewheel"
        class="el-table__footer-wrapper"
      >
        <table-footer
          :store="store"
          :border="border"
          :sum-text="sumText || t('el.table.sumText')"
          :summary-method="summaryMethod"
          :default-sort="defaultSort"
          :style="{
            width: layout.bodyWidth ? layout.bodyWidth + 'px' : '',
          }"
        />
      </div>
      <!-- fixedWrapper -->
      <div
        v-if="fixedColumns.length > 0"
        ref="fixedWrapper"
        v-mousewheel="handleFixedMousewheel"
        :style="[
          {
            width: layout.fixedWidth ? layout.fixedWidth + 'px' : '',
          },
          fixedHeight,
        ]"
        class="el-table__fixed"
      >
        <div
          v-if="showHeader"
          ref="fixedHeaderWrapper"
          class="el-table__fixed-header-wrapper"
        >
          <table-header
            ref="fixedTableHeader"
            :border="border"
            :store="store"
            :style="{
              width: bodyWidth,
            }"
            fixed="left"
          />
        </div>
        <div
          ref="fixedBodyWrapper"
          :style="[
            {
              top: layout.headerHeight + 'px',
            },
            fixedBodyHeight,
          ]"
          class="el-table__fixed-body-wrapper"
        >
          <table-body
            :local="showLocalPagination"
            :page="page"
            :size="newPaginationSize"
            :store="store"
            :stripe="stripe"
            :highlight="highlightCurrentRow"
            :row-class-name="rowClassName"
            :row-style="rowStyle"
            :style="{
              width: bodyWidth,
            }"
            fixed="left"
          />
          <div
            v-if="$slots.append"
            :style="{
              height: layout.appendHeight + 'px',
            }"
            class="el-table__append-gutter"
          />
        </div>
        <div
          v-if="showSummary"
          v-show="data && data.length > 0"
          ref="fixedFooterWrapper"
          class="el-table__fixed-footer-wrapper"
        >
          <table-footer
            :border="border"
            :sum-text="sumText || t('el.table.sumText')"
            :summary-method="summaryMethod"
            :store="store"
            :style="{
              width: bodyWidth,
            }"
            fixed="left"
          />
        </div>
      </div>
      <!-- rightFixedWrapper -->
      <div
        v-if="rightFixedColumns.length > 0"
        ref="rightFixedWrapper"
        v-mousewheel="handleFixedMousewheel"
        :style="[
          {
            width: layout.rightFixedWidth ? layout.rightFixedWidth + 'px' : '',
            right: layout.scrollY
              ? (border ? layout.gutterWidth : layout.gutterWidth || 0) + 'px'
              : '',
          },
          fixedHeight,
        ]"
        class="el-table__fixed-right"
      >
        <div
          v-if="showHeader"
          ref="rightFixedHeaderWrapper"
          class="el-table__fixed-header-wrapper"
        >
          <table-header
            ref="rightFixedTableHeader"
            :border="border"
            :store="store"
            :style="{
              width: bodyWidth,
            }"
            fixed="right"
          />
        </div>
        <div
          ref="rightFixedBodyWrapper"
          :style="[
            {
              top: layout.headerHeight + 'px',
            },
            fixedBodyHeight,
          ]"
          class="el-table__fixed-body-wrapper"
        >
          <table-body
            :local="showLocalPagination"
            :page="page"
            :size="newPaginationSize"
            :store="store"
            :stripe="stripe"
            :row-class-name="rowClassName"
            :row-style="rowStyle"
            :highlight="highlightCurrentRow"
            :style="{
              width: bodyWidth,
            }"
            fixed="right"
          />
        </div>
        <div
          v-if="showSummary"
          v-show="data && data.length > 0"
          ref="rightFixedFooterWrapper"
          class="el-table__fixed-footer-wrapper"
        >
          <table-footer
            :border="border"
            :sum-text="sumText || t('el.table.sumText')"
            :summary-method="summaryMethod"
            :store="store"
            :style="{
              width: bodyWidth,
            }"
            fixed="right"
          />
        </div>
      </div>
      <!-- rightFixedPatch -->
      <div
        v-if="rightFixedColumns.length > 0"
        ref="rightFixedPatch"
        :style="{
          width: layout.scrollY ? layout.gutterWidth + 'px' : '0',
          height: layout.headerHeight + 'px',
        }"
        class="el-table__fixed-right-patch"
      />
      <div
        v-show="resizeProxyVisible"
        ref="resizeProxy"
        class="el-table__column-resize-proxy"
      />
    </div>
    <!-- pagination-wrapper -->
    <div
      v-if="showPagination"
      v-show="!collapsed"
      ref="bottomWrapper"
      class="el-table__pagination"
    >
      <el-pagination-extend
        :disabled="
          loading ||
          pending ||
          pagination.count <= pagination.sizes[0] ||
          excelUploadFlag
        "
        :before-change="beforePagerChange"
        :current-page="pagination.page"
        :page-size="pagination.size"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :total="pagination.count"
        :page-count="11"
        @size-change="(size) => onPagerChange({ current: 1, size })"
        @current-change="(current) => onPagerChange({ current })"
      />
    </div>
    <!-- local pagination-wrapper -->
    <div
      v-if="showLocalPagination"
      v-show="!collapsed"
      ref="bottomWrapper"
      class="el-table__pagination local"
    >
      <el-pagination-extend
        :disabled="newPaginationCount <= 10"
        :before-change="beforeLocalPageChange"
        :current-page="page"
        :page-size="newPaginationSize"
        :page-sizes="pagination.sizes"
        :total-visible="totalVisible"
        :total="newPaginationCount"
        :page-count="11"
        layout="total, prev, pager, next, ->, sizes"
        @size-change="(size) => onLocalPageChange({ current: 1, size })"
        @current-change="
          (current) => onLocalPageChange({ current, size: newPaginationSize })
        "
      />
    </div>

    <!-- 증명서 출력 팝업 -->
    <el-dialog
      v-if="printCertiVisible"
      :visible.sync="printCertiVisible"
      :title="lang('미리보기')"
      width="825px"
      class="certificate-dialog"
      @close="printClose"
    >
      <print-certificate
        :wish-ymd="wishYmd"
        :certificate-id="perCertificateId"
        :origin-print-certi-data="originPrintCertiData"
        @close="printClose"
      />
    </el-dialog>
    <!-- 법인 선택 팝업 -->
    <el-dialog
      v-if="isCompany"
      :visible.sync="isCompany"
      :title="lang('법인선택')"
      width="300px"
      @close="selectedCompanyClose"
    >
      <panel :add-header="false" :add-footer="true" :inner-padding="true">
        <el-form>
          <el-row type="flex" align="middle" :gutter="8">
            <el-col>
              <el-form-item>
                <span slot="label">{{ lang("법인") }}</span>
                <el-select v-model="selectedCompanyOriginalId" size="small">
                  <el-option
                    v-for="(item, index) in companyList"
                    :key="`${index}_option3`"
                    :value="item.companyId"
                    :label="item.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle" justify="end">
            <el-button type="primary" size="small" @click="selectCompany()"
              >{{ $tc("el.table.selectText") }}
            </el-button>
          </el-row>
        </el-form>
      </panel>
    </el-dialog>
    <!-- 구성원 등록 팝업 -->
    <employee-registration
      v-if="employeeRegistrationVisible"
      v-model="employeeRegistrationVisible"
      :selected-company-original-id="selectedCompanyOriginalId"
      :msg-flag="employeeButtonOption.msgFlag"
      @close="employeeRegistrationClose"
    />
  </div>
</template>

<script type="text/babel">
import ElCheckbox from "element-ui/packages/checkbox";
import debounce from "throttle-debounce/debounce";
import {
  addResizeListener,
  removeResizeListener,
} from "element-ui/src/utils/resize-event";
import Mousewheel from "element-ui/src/directives/mousewheel";
import Clickoutside from "element-ui/src/utils/clickoutside";
import Locale from "element-ui/src/mixins/locale";
import merge from "element-ui/src/utils/merge";
import Migrating from "element-ui/src/mixins/migrating";
import TableStore from "./table-store";
import TableLayout from "./table-layout";
import TableBody from "./table-body";
import TableHeader from "./table-header";
import TableFooter from "./table-footer";
import { eventPermissionCheck, pixelToNumber } from "@/lib/util";
import { exportExcel, importExcel } from "@/lib/xlsx";
import HAS1200 from "@/pages/POPUP/HAS1200.vue";
import HPE1915 from "@/pages/POPUP/HPE1915.vue";
import { i18nTranslator } from "@/mixins/i18n";
import helpers from "@/auth/helpers";
import { mapGetters } from "vuex";
import moment from "moment";
import currentTabFetchMixin from "@/mixins/currentTabFetchMixin";
import printCertificate from "@/pages/POPUP/printCertificate.vue";
import employeeRegistration from "@/pages/POPUP/employeeRegistration.vue";
import { AUTH_COMPANY_TYPE, CORP_COMBO_SHOW_FLAG } from "@/HRConstant";
import { CommonAPI } from "@/services/api";
import Panel from "@/components/portlets/Panel";

import masterModel from "@/services/model/HAS/HAS1030M01";
import date from "@/lib/date";

function noop() {}

let tableIdSeed = 1;

export default {
  name: "ElTableExtend",

  directives: {
    Mousewheel,
    Clickoutside,
  },

  components: {
    TableHeader,
    TableFooter,
    TableBody,
    // eslint-disable-next-line vue/no-unused-components
    ElCheckbox,
    HAS1200,
    HPE1915,
    printCertificate,
    employeeRegistration,
    Panel,
  },

  mixins: [
    i18nTranslator("TableGrid"),
    Locale,
    Migrating,
    currentTabFetchMixin,
  ],

  props: {
    isDetailTable: {
      type: Boolean,
      default: false,
    },
    isMasking: {
      type: Boolean,
      default: true,
    },
    legend: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showLocalPagination: {
      type: Boolean,
      default: false,
    },
    notice: {
      type: String,
      default: undefined,
    },
    programCode: {
      type: String,
      default: null,
    },

    editable: {
      type: Boolean,
      default: false,
    },

    searchAssign: {
      type: Boolean,
      default: false,
    },

    totalVisible: {
      type: Boolean,
      default: true,
    },

    softSort: {
      type: Boolean,
      default: true,
    },

    cancelButtonOption: {
      type: Object,
      default() {
        return {
          hidden: false,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    addButtonOption: {
      type: Object,
      default() {
        return {
          hidden: false,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    copyButtonOption: {
      type: Object,
      default() {
        return {
          hidden: true,
          disabled: false,
          clearFields: null,
          handler: null, // useable user function
        };
      },
    },

    removeButtonOption: {
      type: Object,
      default() {
        return {
          hidden: false,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    saveButtonOption: {
      type: Object,
      default() {
        return {
          hidden: false,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    downloadButtonOption: {
      type: Object,
      default() {
        return {
          hidden: false,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    uploadButtonOption: {
      type: Object,
      default() {
        return {
          hidden: true,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    choiceButtonOption: {
      type: Object,
      default() {
        return {
          hidden: false,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    assignButtonOption: {
      type: Object,
      default() {
        return {
          hidden: false,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    employeeButtonOption: {
      type: Object,
      default() {
        return {
          hidden: true,
          disabled: false,
          handler: null, // useable user function
          msgFlag: false,
          companyFlag: false,
        };
      },
    },

    copyYearHoliday: {
      type: Object,
      default() {
        return {
          hidden: true,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    copyCompanyHoliday: {
      type: Object,
      default() {
        return {
          hidden: true,
          disabled: false,
          handler: null, // useable user function
        };
      },
    },

    // 행추가 dataset
    // eslint-disable-next-line vue/require-default-prop
    emptyModel: {
      type: Object,
    },

    xlsxHandler: {
      type: Function,
      default: () => null,
    },

    xlsxDownload: {
      type: Function,
      default: null,
    },

    /*************************
     * pagination attributes */
    showPagination: {
      type: Boolean,
      default: true,
    },

    excelUploadFlag: {
      type: Boolean,
      default: false,
    },

    paginationCount: {
      type: Number,
      default: 0,
    },

    paginationLimit: {
      type: [String, Number],
      default: 10,
    },

    paginationSizes: {
      type: Array,
      default: () => [10, 20, 30],
      validator: (sizes) => {
        const maxSize = 500;
        const valid = sizes.every((size) => size <= maxSize);
        if (!valid) {
          throw new Error(`paginationSizes must be less then ${maxSize}`);
        }
        return valid;
      },
    },

    paginationPage: {
      type: Number,
      default: 1,
    },

    paginationLayout: {
      type: String,
      default: "total, prev, pager, next, ->, sizes",
    },
    /* pagination attributes
     ***********************/

    data: {
      type: Array,
      default: function () {
        return [];
      },
    },

    // eslint-disable-next-line vue/require-default-prop
    size: String,

    // eslint-disable-next-line vue/require-default-prop
    width: [String, Number],

    // eslint-disable-next-line vue/require-default-prop
    height: [String, Number],

    maxHeight: {
      type: [String, Number],
      default: null,
    },

    fit: {
      type: Boolean,
      default: true,
    },

    stripe: {
      type: Boolean,
      default: false,
    },

    border: {
      type: Boolean,
      default: true,
    },

    rowKey: {
      type: [String, Function],
      required: true,
    },

    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    context: {},

    showHeader: {
      type: Boolean,
      default: true,
    },

    showTopBar: {
      type: Boolean,
      default: false,
    },

    // eslint-disable-next-line vue/require-default-prop
    showSummary: Boolean,

    // eslint-disable-next-line vue/require-default-prop
    labels: Array,

    // eslint-disable-next-line vue/require-default-prop
    sumText: String,

    // eslint-disable-next-line vue/require-default-prop
    summaryMethod: Function,

    // eslint-disable-next-line vue/require-default-prop
    rowClassName: [String, Function],

    // eslint-disable-next-line vue/require-default-prop
    rowStyle: [Object, Function],

    // eslint-disable-next-line vue/require-default-prop
    cellClassName: [String, Function],

    // eslint-disable-next-line vue/require-default-prop
    cellStyle: [Object, Function],

    // eslint-disable-next-line vue/require-default-prop
    headerRowClassName: [String, Function],

    // eslint-disable-next-line vue/require-default-prop
    headerRowStyle: [Object, Function],

    // eslint-disable-next-line vue/require-default-prop
    headerCellClassName: [String, Function],

    // eslint-disable-next-line vue/require-default-prop
    headerCellStyle: [Object, Function],

    // eslint-disable-next-line vue/require-default-prop
    highlightCurrentRow: Boolean,

    // eslint-disable-next-line vue/require-default-prop
    currentRowKey: [String, Number],

    // eslint-disable-next-line vue/require-default-prop
    emptyText: String,

    // eslint-disable-next-line vue/require-default-prop
    expandRowKeys: Array,

    // eslint-disable-next-line vue/require-default-prop
    defaultExpandAll: Boolean,

    // eslint-disable-next-line vue/require-default-prop
    defaultSort: Object,

    tooltipEffect: {
      type: String,
      default: "dark",
    },

    // eslint-disable-next-line vue/require-default-prop
    spanMethod: Function,

    selectOnIndeterminate: {
      type: Boolean,
      default: true,
    },

    totalCount: {
      type: [String, Number],
      default: "0",
    },
    // eslint-disable-next-line vue/require-default-prop
    hireYmdStart: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    hireYmdEnd: {
      type: String,
    },
    prevYearValue: {
      type: String,
      default: "",
    },
    originalCompanyId: {
      type: Number,
      default: 0,
    },
    selectCompanyOptions: {
      type: Number,
      default: 0,
    },
    offMask: {
      type: Function,
      default: () => null,
    },
    // eslint-disable-next-line vue/require-default-prop
    certificateId: [String, Number],
    // eslint-disable-next-line vue/require-default-prop
    employeeId: [String, Number],
    printHeight: {
      type: String,
      default: "1000px",
    },
    searchPanelHeight: {
      type: Number,
      default: 0,
    },
    tableChildCount: {
      type: Number,
      default: 1,
    },
    arrayTitle: {
      type: Object,
      default() {
        return {
          list: [],
          color: null,
        };
      },
    },
  },

  data() {
    const store = new TableStore(this, {
      rowKey: this.rowKey,
      defaultExpandAll: this.defaultExpandAll,
      selectOnIndeterminate: this.selectOnIndeterminate,
    });
    const layout = new TableLayout({
      store,
      table: this,
      fit: this.fit,
      showHeader: this.showHeader,
      showPagination: this.showPagination,
    });
    // const lang = this.lang;
    return {
      wishYmd: null,
      layout,
      store,
      isHidden: false,
      renderExpanded: null,
      resizeProxyVisible: false,
      resizeState: {
        width: null,
        height: null,
      },
      // 是否拥有多级表头
      isGroup: false,
      scrollPosition: "left",
      collapsed: false,
      pagination: {
        count: this.paginationCount,
        size: this.paginationLimit,
        sizes: this.paginationSizes,
        page: this.paginationPage,
        layout: this.paginationLayout,
      },
      newPaginationSize: this.paginationSizes?.includes(500) ? 500 : 30,
      page: 1,
      assignSearchStr: null,
      findAssignDialogVisible: false,
      newCertificateVisible: false,
      searchParams: {
        documentId: null,
        documentNo: null,
        documentTitle: null,
      },
      selectedCompanies: [],
      selectedCompanyId: [],
      // selectedCompaniesList: [],
      pending: false,
      printCertiVisible: false,
      originPrintCertiData: {},
      employeeRegistrationVisible: false,
      isCompany: false, // 법인선택
      selectedCompanyOriginalId: null,
      companyList: null,
    };
  },

  computed: {
    ...mapGetters("user", {
      user: "getUserInfo",
      store$headerAuth: "headerAuth",
      programs: "programs",
    }),
    ...mapGetters("common", { companyListOptions: "companyListOptions" }),
    headerAuth() {
      return this.store$headerAuth[this.currentTab];
    },
    corpComboShowFlag() {
      // '법인콤보여부:
      // N.미표시
      // Y.표시(전체미포함)
      // A.표시(전체포함)'
      let value = CORP_COMBO_SHOW_FLAG.NOT;
      try {
        value = this.headerAuth.corporationComboYn;
      } catch (e) {
        // continue regardless of error
      }
      return value;
    },
    showCorpCombo() {
      // Y또는 A 인경우 법인콤보가 보인다.
      return [
        CORP_COMBO_SHOW_FLAG.SHOW,
        CORP_COMBO_SHOW_FLAG.WITH_ALL,
      ].includes(this.corpComboShowFlag);
    },
    program() {
      return this.programs[this.currentTab];
    },
    filteredOptions() {
      const corpList = this.companyListOptions.filter((item) => {
        return item.companyId !== this.originalCompanyId;
      });
      return corpList;
    },
    newPaginationCount() {
      return this.store.states && this.store.states.data
        ? this.store.states.data.length
        : 0;
    },
    isDevelopment() {
      return (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "local"
      );
    },
    _editable() {
      if (this.editable) {
        if (this.programCode) {
          return eventPermissionCheck("U", this.programCode);
        }
        return true;
      }
      return false;
    },
    tableStyle() {
      let style = {
        position: "relative", // for fixed column
        // height: 'calc(100% - 32px)'
      };
      if (this.collapsed) {
        return merge(style, {
          height: "1px",
          visibility: "hidden",
        });
      }
      return style;
    },
    tableSize() {
      return this.size || (this.$ELEMENT || {}).size;
    },

    bodyWrapper() {
      return this.$refs.bodyWrapper;
    },

    shouldUpdateHeight() {
      return (
        this.height ||
        this.maxHeight ||
        this.fixedColumns.length > 0 ||
        this.rightFixedColumns.length > 0
      );
    },

    selection() {
      return this.store.states.selection;
    },

    columns() {
      return this.store.states.columns;
    },

    tableData() {
      return this.store.states.data;
    },

    fixedColumns() {
      return this.store.states.fixedColumns;
    },

    rightFixedColumns() {
      return this.store.states.rightFixedColumns;
    },

    bodyWidth() {
      const { bodyWidth, scrollY, gutterWidth } = this.layout;
      return bodyWidth ? bodyWidth - (scrollY ? gutterWidth : 0) + "px" : "";
    },

    bodyHeight() {
      if (this.height) {
        return {
          height: this.layout.bodyHeight
            ? this.layout.bodyHeight - 10 + "px"
            : "",
        };
      } else if (this.maxHeight) {
        return {
          "max-height":
            (this.showHeader
              ? this.maxHeight -
                this.layout.headerHeight -
                this.layout.footerHeight -
                10
              : this.maxHeight - this.layout.footerHeight) -
            10 +
            "px",
        };
      }
      const fullHeight = window.innerHeight;
      const fixedHeight = 200; // 높이 고정 값 tab, Breadcrumb, padding 등
      const tableTitleHeight = this.showTopBar ? this.layout.headerHeight : 0; //
      const tableHeader = 56 * this.tableChildCount; // 테이블 컬럼에 child 있는 경우 height * 2
      return {
        height:
          fullHeight -
          fixedHeight -
          tableTitleHeight -
          tableHeader -
          this.searchPanelHeight -
          this.layout.footerHeight -
          10 +
          "px",
      };
    },

    fixedBodyHeight() {
      if (!this.bodyHeight?.height) {
        return {};
      }
      return {
        height: pixelToNumber(this.bodyHeight.height) - 15 + "px",
      };
    },

    fixedHeight() {
      if (this.maxHeight) {
        if (this.showSummary) {
          return {
            bottom: 0,
          };
        }
        return {
          bottom:
            this.layout.scrollX && this.data.length
              ? this.layout.gutterWidth + "px"
              : "",
        };
      } else {
        if (this.showSummary) {
          return {
            height: this.layout.tableHeight
              ? this.layout.tableHeight + "px"
              : "",
          };
        }

        if (!this.bodyHeight?.height) {
          return {};
        }

        return {
          height: pixelToNumber(this.bodyHeight.height) + 40 + "px",
        };
      }
    },
    downloadButtonDisabled() {
      if (this.loading || this.pending || this.downloadButtonOption.disabled) {
        return true;
      }
      return false;
    },
    cancelButtonDisabled() {
      if (this.loading || this.pending || this.cancelButtonOption.disabled) {
        return true;
      }
      return false;
    },
    addButtonDisabled() {
      if (this.loading || this.addButtonOption.disabled) {
        return true;
      }
      return false;
    },
    copyButtonDisabled() {
      if (this.loading || this.pending || this.copyButtonOption.disabled) {
        return true;
      }
      return false;
    },
    removeButtonDisabled() {
      if (this.loading || this.pending || this.removeButtonOption.disabled) {
        return true;
      }
      return false;
    },
    saveButtonDisabled() {
      if (this.loading || this.pending || this.saveButtonOption.disabled) {
        return true;
      }
      return false;
    },
    uploadButtonDisabled() {
      if (this.loading || this.pending || this.uploadButtonOption.disabled) {
        return true;
      }
      return false;
    },
    choiceButtonDisabled() {
      if (this.loading || this.pending || this.choiceButtonOption.disabled) {
        return true;
      }
      return false;
    },
    assignButtonDisabled() {
      if (this.loading || this.pending || this.assignButtonOption.disabled) {
        return true;
      }
      return false;
    },
    employeeButtonDisabled() {
      if (this.loading || this.pending || this.employeeButtonOption.disabled) {
        return true;
      }
      return false;
    },
    copyCompanyHolidayDisabled() {
      if (this.loading || this.pending || this.copyCompanyHoliday.disabled) {
        return true;
      }
      return false;
    },
    copyPreviousYearHolidayDisabled() {
      if (this.loading || this.pending || this.copyYearHoliday.disabled) {
        return true;
      }
      return false;
    },
    downloadButtonHidden() {
      if (this.downloadButtonOption.hidden) {
        return true;
      }
      if (this.programCode) {
        // 프로그램 이벤트 권한
        return eventPermissionCheck("R", this.programCode) === false;
      }
      return false;
    },
    uploadButtonHidden() {
      if (this.uploadButtonOption.hidden) {
        return true;
      }
      if (this.programCode) {
        // 프로그램 이벤트 권한
        return eventPermissionCheck("C", this.programCode) === false;
      }
      return false;
    },
    cancelButtonHidden() {
      if (this.cancelButtonOption.hidden) {
        return true;
      }
      if (this.programCode) {
        // 프로그램 이벤트 권한
        return eventPermissionCheck("U", this.programCode) === false;
      }
      return false;
    },
    addButtonHidden() {
      if (this.addButtonOption.hidden) {
        return true;
      }
      if (this.programCode) {
        // 프로그램 이벤트 권한
        return eventPermissionCheck("C", this.programCode) === false;
      }
      return false;
    },
    copyButtonHidden() {
      if (this.copyButtonOption.hidden) {
        return true;
      }
      if (this.programCode) {
        // 프로그램 이벤트 권한
        return eventPermissionCheck("C", this.programCode) === false;
      }
      return false;
    },
    removeButtonHidden() {
      const hasDeletionColumn = this.store.states.columns.some(
        (column) => column.type === "deletion",
      );
      if (hasDeletionColumn) {
        if (this.removeButtonOption.hidden) {
          return true;
        }
        if (this.programCode) {
          // 프로그램 이벤트 권한
          return eventPermissionCheck("D", this.programCode) === false;
        }
        return false;
      }
      return true;
    },
    saveButtonHidden() {
      if (this.saveButtonOption.hidden) {
        return true;
      }
      if (this.programCode) {
        // 프로그램 이벤트 권한
        return eventPermissionCheck("U", this.programCode) === false;
      }
      return false;
    },
    choiceButtonHidden() {
      if (this.choiceButtonOption.hidden) {
        return true;
      }
      return false;
    },
    assignButtonHidden() {
      if (this.assignButtonOption.hidden) {
        return true;
      }
      return false;
    },
    employeeButtonHidden() {
      if (this.employeeButtonOption.hidden) {
        return true;
      }
      return false;
    },
    copyCompanyHolidayButtonHidden() {
      if (this.copyCompanyHoliday.hidden) {
        return true;
      }
      return false;
    },
    copyYearHolidayButtonHidden() {
      if (this.copyYearHoliday.hidden) {
        return true;
      }
      return false;
    },
    companyId() {
      return this.$store.getters["common/searchParams"].lookupValueId;
    },
    cancelEducationCheck() {
      if (this.data && this.data.length > 0) {
        const checkedList = this.data.map((el) => el.checkboxSelect);

        return !checkedList.includes("Y");
      } else {
        return true;
      }
    },
  },

  watch: {
    paginationLimit: {
      immediate: true,
      handler(size) {
        this.pagination.size = size;
        if (this.showLocalPagination && this.paginationSizes.includes(size)) {
          this.newPaginationSize = size;
        }
      },
    },

    paginationCount: {
      immediate: true,
      handler(count) {
        this.pagination.count = count;
      },
    },

    paginationPage: {
      immediate: true,
      handler(page) {
        this.pagination.page = page;
      },
    },

    height: {
      immediate: true,
      handler(value) {
        this.layout.setHeight(value);
      },
    },

    maxHeight: {
      immediate: true,
      handler(value) {
        this.layout.setMaxHeight(value);
      },
    },

    currentRowKey(newVal) {
      this.store.setCurrentRowKey(newVal);
    },

    data: {
      immediate: true,
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.store.commit("setData", this.mapData(value));
          this.page = 1;
        }
        if (this.$ready) {
          this.$nextTick(() => {
            this.doLayout();
          });
        }
      },
    },

    collapsed: {
      immediate: true,
      handler() {
        if (this.$ready) {
          this.$nextTick(() => {
            this.doLayout();
          });
        }
      },
    },

    expandRowKeys: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.store.setExpandRowKeys(newVal);
        }
      },
    },

    companyOriginalId: {
      handler() {
        this.selectedCompanies = [];
      },
    },
  },

  created() {
    // eslint-disable-next-line no-unused-vars
    let parent = null;
    this.tableId = "el-table_" + tableIdSeed++;
    this.debouncedUpdateLayout = debounce(50, () => this.doLayout());
  },

  destroyed() {
    if (this.resizeListener) {
      removeResizeListener(this.$el, this.resizeListener);
    }
  },

  mounted() {
    this.bindEvents();
    this.store.updateColumns();
    this.doLayout();

    this.store.states.softSort = this.softSort;
    this.resizeState = {
      width: this.$el.offsetWidth,
      height: this.$el.offsetHeight,
    };

    // init filters
    this.store.states.columns.forEach((column) => {
      if (column.filteredValue && column.filteredValue.length) {
        this.store.commit("filterChange", {
          column,
          values: column.filteredValue,
          silent: true,
        });
      }
    });

    this.$ready = true;
  },

  methods: {
    async handleUpload({ raw }) {
      if (!raw?.name?.includes(".xls")) {
        this.$alert(
          this.$t("common.onlyXls"),
          this.$t("common.버튼.엑셀업로드"),
        );
        return;
      }

      try {
        this.pending = true;
        const parsed = await importExcel(raw, this.labels);
        this.xlsxHandler(this, parsed);
      } catch (e) {
        this.$alert(
          this.$t("common.xlsParseError"),
          this.$t("common.버튼.엑셀업로드"),
        );
      }
      this.pending = false;
    },

    handleRemove() {
      // console.log(args);
    },

    async download() {
      // 엑셀에 신규 데이터가 있을시 저장필요 문구 추가
      const list = this.store.states.data.filter(
        (vo) => vo.manipulationType === "C",
      );

      if (list.length > 0) {
        this.$message({
          type: "info",
          message: this.$tc("el.table.pleaseProcessTheUpdation"), // 변경된 데이터가 있습니다. 저장후 진행해주세요.
        });
        return;
      }

      this.pending = true;
      let program = "program." + this.programCode;
      let text = this.$tc(program);
      text = text.replace("/", " ");
      text = text.replace("*", " ");
      text = text.replace("[", " ");
      text = text.replace("]", " ");
      text = text.replace("?", " ");
      text = text.replace("\\", " ");
      if (this.xlsxDownload) {
        await this.xlsxDownload();
      } else {
        exportExcel(
          text + "_" + moment().format("YYYY_MM_DD"),
          this.labels,
          this.data,
        );
      }
      this.pending = false;
    },
    // table row 강제 re render
    forceRender() {
      this.store.states.data.push({});
      this.store.states.data.pop();
    },
    updateRow(row, obj) {
      this.store.commit("updateData", row, obj);
      this.forceRender();
    },

    addRow(row) {
      this.$emit("addrow");
      const newRow = merge({}, row);
      // 복사와 추가인 경우 키중복방지 등 처리
      // eslint-disable-next-line no-prototype-builtins
      if (newRow.hasOwnProperty(this.rowKey) === true) {
        delete newRow[this.rowKey];
      }
      this.store.commit("addRow", this.mapData([newRow])[0]);
    },

    handleButtonClick({ handler }, defaultHandler) {
      if (handler) {
        const result = handler(this.store.states.data);
        if (result === false) {
          return;
        }
      }

      defaultHandler();
    },
    onSaveClick() {
      const changedRows = this.store.getChangedRows();
      if (changedRows.length === 0) {
        this.$message({
          type: "info",
          message: this.$tc("el.table.modifiedDataNotFound"), // 변경된 데이터가 없습니다.
        });
        return;
      }

      if (this.validateColumns(changedRows)) {
        const deleteCheckedRows = changedRows.filter(
          (row) => row.checkboxDelete,
        );
        if (deleteCheckedRows.length) {
          this.$message({
            type: "info",
            message: this.$tc("el.table.pleaseProcessTheRemoval"), // 삭제 선택된 데이터가 있습니다. 처리후 진행해 주세요.
          });
          return;
        }
        this.pending = true;

        this.$emit(
          "save",
          changedRows,
          () => {
            this.store.commit("clearStatus");
          },
          "U",
        );
        this.pending = false;
      }
    },

    onRemoveClick() {
      const changedRows = this.store.getChangedRows();
      const deleteCheckedRows = changedRows.filter((row) => row.checkboxDelete);
      if (deleteCheckedRows.length === 0) {
        this.$message({
          type: "info",
          message: this.$tc("el.table.removalDataNotFound"), // 삭제할 데이터가 없습니다.
        });
        return;
      }
      if (changedRows.length > deleteCheckedRows.length) {
        this.$message({
          type: "info",
          message: this.$tc("el.table.pleaseProcessTheUpdation"), // 변경된 데이터가 있습니다. 저장후 진행해주세요.
        });
        return;
      }
      this.$confirm(
        this.$tc("el.table.confirmMessage"),
        this.$tc("el.table.confirmTitle"),
        `${this.legend || ""}`,
        {
          type: "warning",
          confirmButtonText: this.$tc("el.table.confirmButtonText"),
          cancelButtonText: this.$tc("el.table.cancelButtonText"),
        },
      ).then(() => {
        this.pending = true;
        this.$emit(
          "save",
          changedRows,
          () => {
            this.store.commit("clearStatus");
          },
          "D",
        );
        this.pending = false;
      });
    },

    onCancelClick() {
      const changedRows = this.store.getChangedRows();

      if (changedRows.length === 0) {
        this.$message({
          type: "info",
          message: this.$tc("el.table.cancelDataNotFound"),
        });
        return;
      }

      this.pending = true;
      this.store.revertAll((originalData) => {
        if (
          originalData[0] &&
          originalData[0].xlsx !== undefined &&
          originalData[0].xlsx === true
        ) {
          this.$emit("fetch");
        }
      });
      this.pending = false;
    },

    /**
     * @desc 그리드 Row 추가
     * `emptyModel` property is required to add row.
     */
    onAddClick() {
      const row = this.emptyModel;
      if (typeof row !== "object") {
        throw new Error("empty-model prop is required.");
      }

      this.page > 1 && this.onLocalPageChange({ current: 1 });
      this.addRow(row);
    },

    /**
     * @desc 그리드 currentRow 복사
     * copy currentRow.
     */
    onCopyClick() {
      const copyRow = Object.assign({}, this.store.states.currentRow);
      this.copyButtonOption.clearFields.map((field) => {
        copyRow[field] = null;
      });
      this.addRow(copyRow);
    },

    mapData(data) {
      for (let rowIndex in data) {
        // emptyModel에 아래의 필수 프로퍼티들이 없을경우 할당.
        // eslint-disable-next-line no-prototype-builtins
        if (data[rowIndex].hasOwnProperty("checkboxSelect") === false) {
          data[rowIndex].checkboxSelect = null;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (data[rowIndex].hasOwnProperty("checkboxDelete") === false) {
          data[rowIndex].checkboxDelete = null;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (data[rowIndex].hasOwnProperty("manipulationType") === false) {
          data[rowIndex].manipulationType = null;
        }
      }
      return data;
    },

    validateColumns(rows) {
      let valid = true;
      const columns = this.columns.filter(
        (column) => typeof column.validator === "function",
      );

      // 그리드 데이터의 유효성 검증을 실행한다
      for (let rowIndex in rows) {
        if (!valid) break;
        for (let column of columns) {
          let cellValue = rows[rowIndex][column.property];
          if (!column.validator(rows[rowIndex], column, cellValue)) {
            valid = false;
            this.store.commit("setCurrentCell", column, rows[rowIndex]);
            if (typeof column.onInvalid === "function") {
              column.onInvalid(rows[rowIndex], column, cellValue);
            }
            break;
          }
        }
      }

      return valid;
    },

    onPagerChange(paginationObj) {
      let { current, size } = merge(this.pagination, paginationObj);
      this.$emit("page-change", {
        page: current,
        limit: size,
      });
    },

    onLocalPageChange({ current = 1, size = 30 }) {
      this.page = current;
      this.newPaginationSize = size;
    },

    beforeLocalPageChange(resolve) {
      resolve();
    },

    beforePagerChange(resolve) {
      const inProcessingData = this.store.inProcessData();
      if (inProcessingData.length) {
        // eslint-disable-next-line no-unused-vars
        const confirm = this.$confirm(
          this.$tc(`el.table.unsavedWarningMessage`),
          {
            type: "warning",
          },
        )
          .then(() => {
            resolve();
          })
          .catch(noop);
        return;
      }
      resolve();
    },

    getMigratingConfig() {
      return {
        events: {
          expand: "expand is renamed to expand-change",
        },
      };
    },

    setCurrentRow(row) {
      this.store.commit("setCurrentRow", row);
    },
    setCurrentRowFirstData() {
      this.setCurrentRow(this.data[0]);
    },

    toggleRowSelection(row, selected) {
      this.store.toggleRowSelection(row, selected);
      this.store.updateAllSelected();
    },

    toggleRowDeletion(row, deleted) {
      this.store.toggleRowDeletion(row, deleted);
    },

    toggleRowExpansion(row, expanded) {
      this.store.toggleRowExpansion(row, expanded);
    },

    clearSelection() {
      this.store.clearSelection();
    },

    clearDeletion() {
      this.store.clearDeletion();
    },

    clearFilter() {
      this.store.clearFilter();
    },

    clearSort() {
      this.store.clearSort();
    },

    handleMouseLeave() {
      this.store.commit("setHoverRow", null);
      if (this.hoverState) this.hoverState = null;
    },

    updateScrollY() {
      this.layout.updateScrollY();
      this.layout.updateColumnsWidth();
    },

    handleFixedMousewheel(event, data) {
      const bodyWrapper = this.bodyWrapper;
      if (Math.abs(data.spinY) > 0) {
        const currentScrollTop = bodyWrapper.scrollTop;
        if (data.pixelY < 0 && currentScrollTop !== 0) {
          event.preventDefault();
        }
        if (
          data.pixelY > 0 &&
          bodyWrapper.scrollHeight - bodyWrapper.clientHeight > currentScrollTop
        ) {
          event.preventDefault();
        }
        bodyWrapper.scrollTop += Math.ceil(data.pixelY / 5);
      } else {
        bodyWrapper.scrollLeft += Math.ceil(data.pixelX / 5);
      }
    },

    handleHeaderFooterMousewheel(event, data) {
      const { pixelX, pixelY } = data;
      if (Math.abs(pixelX) >= Math.abs(pixelY)) {
        event.preventDefault();
        this.bodyWrapper.scrollLeft += data.pixelX / 5;
      }
    },

    bindEvents() {
      const { headerWrapper, footerWrapper } = this.$refs;
      const refs = this.$refs;
      let self = this;

      this.bodyWrapper.addEventListener("scroll", function () {
        if (headerWrapper) headerWrapper.scrollLeft = this.scrollLeft;
        if (footerWrapper) footerWrapper.scrollLeft = this.scrollLeft;
        if (refs.fixedBodyWrapper) {
          refs.fixedBodyWrapper.scrollTop = this.scrollTop;
        }
        if (refs.rightFixedBodyWrapper) {
          refs.rightFixedBodyWrapper.scrollTop = this.scrollTop;
        }
        const maxScrollLeftPosition = this.scrollWidth - this.offsetWidth - 1;
        const scrollLeft = this.scrollLeft;
        if (scrollLeft >= maxScrollLeftPosition) {
          self.scrollPosition = "right";
        } else if (scrollLeft === 0) {
          self.scrollPosition = "left";
        } else {
          self.scrollPosition = "middle";
        }
      });

      if (this.fit) {
        addResizeListener(this.$el, this.resizeListener);
      }
    },

    resizeListener() {
      if (!this.$ready) return;
      let shouldUpdateLayout = false;
      const el = this.$el;
      const { width: oldWidth, height: oldHeight } = this.resizeState;

      const width = el.offsetWidth;
      if (oldWidth !== width) {
        shouldUpdateLayout = true;
      }

      const height = el.offsetHeight;
      if ((this.height || this.shouldUpdateHeight) && oldHeight !== height) {
        shouldUpdateLayout = true;
      }

      if (shouldUpdateLayout) {
        this.resizeState.width = width;
        this.resizeState.height = height;
        this.doLayout();
      }
    },

    doLayout() {
      this.layout.updateColumnsWidth();
      if (this.shouldUpdateHeight) {
        this.layout.updateElsHeight();
      }
    },

    sort(prop, order) {
      this.store.commit("sort", { prop, order });
    },

    toggleAllSelection() {
      this.store.commit("toggleAllSelection");
    },

    isEditableColumn(column) {
      if (this._editable) {
        if (column.useTemplate) {
          return true;
        }
        if (column.type) {
          const editableTypes = [
            "text",
            "textarea",
            "number",
            "select",
            "date",
            "month",
            "year",
            "checkbox",
          ];
          return editableTypes.indexOf(column.type) !== -1;
        }
      }
      return false;
    },

    setAssign(row) {
      // 발령번호찾기 팝업 data
      const { documentTitle, documentNo, documentId } = row;
      this.assignSearchStr = documentTitle;
      this.searchParams.documentNo = documentNo;
      this.searchParams.documentId = documentId;
      this.findAssignDialogVisible = false;
    },

    handleSearch(value) {
      // 발령번호찾기 enter
      const { documentTitle } = value;
      this.assignSearchStr = documentTitle;
    },

    resetState() {
      this.assignSearchStr = null;
      this.searchParams.documentNo = null;
      this.findAssignDialogVisible = false;
    },
    // 제증명신청 팝업
    setCertificate() {},

    // HPE1910에 팝업 열라고 보냄
    openCertificate(flag) {
      this.$emit("open-certificate", flag);
    },
    async printCertificate(data) {
      try {
        this.pending = true;
        const selectChkRow = data.filter((row) => row.checkboxSelect === "Y");
        if (selectChkRow.length !== 1) {
          this.$message({
            type: "warning",
            message: this.$tc("el.table.selectCertificate"),
          });
        } else {
          const row = selectChkRow[0];
          this.wishYmd = row.wishYmd;
          this.perCertificateId = row.perCertificateId;

          const {
            data: { payload },
          } = await helpers.get(`/proof/apply/print/${this.perCertificateId}`);
          this.originPrintCertiData = payload;
          this.printCertiVisible = true;
        }
      } catch (e) {
        // continue regardless of error
      } finally {
        this.pending = false;
      }
    },
    printClose() {
      this.pending = false;
      this.printCertiVisible = false;
      this.$emit("close-printcertificate");
    },
    getTableData() {
      return this.tableData;
    },
    getChangedRows() {
      return this.store.getChangedRows();
    },
    async choicedRow(data) {
      const selectChk = data.filter((row) => row.checkboxSelect);
      const documentChk = this.searchParams.documentNo;

      if (data.length === 0) {
        this.$message({
          type: "warning",
          message: this.$tc("el.table.noApplyObject"), // 적용할 대상이 없습니다.
        });
        return;
      }
      // -- 발령서 목록 검색 여부 체크
      if (documentChk === null || documentChk === "") {
        this.$message({
          type: "warning",
          message: this.$tc("el.table.documentSearch"), // 발령서 목록을 검색해주세요.
        });
        return;
      }
      // -- 대상자 미 선택 여부 체크
      if (selectChk.length === 0) {
        this.$message({
          type: "warning",
          message: this.$tc("el.table.selectEmployees"), // 대상자를 먼저 선택해주세요.
        });
        return;
      }

      this.$emit("hpe-change", {
        documentId: this.searchParams.documentId,
        documentNo: this.searchParams.documentNo,
      });

      let messageFirst =
        "[" +
        this.searchParams.documentNo +
        "]" +
        this.$tc("el.table.assignDocumentAddSuccess");

      this.$message({
        type: "success",
        message: messageFirst, // 대상자를 먼저 선택해주세요.
      });
    },
    // -- 발령등록 버튼
    async assignedRow(data) {
      let loading;
      this.$confirm(
        this.$tc("el.table.confirmAssignments"),
        `${this.legend || ""}`,
        {
          type: "warning",
          confirmButtonText: this.$tc("el.table.confirmButtonText"),
          cancelButtonText: this.$tc("el.table.cancelButtonText"),
        },
      )
        .then(async () => {
          if (data.length === 0) {
            this.$message({
              type: "warning",
              message: this.$tc("el.table.noApplyObject"), // 적용할 대상이 없습니다.
            });
            return;
          }

          const checkedRows = data.filter(
            (row) => row.checkboxSelect || row.checkboxDelete,
          );

          if (!checkedRows.length) {
            return this.$message({
              type: "warning",
              message: this.$tc("el.table.selectEmployees"), // 대상자를 선택해 주세요.
            });
          }

          const deleteCheckedRows = checkedRows.filter(
            (row) => row.checkboxDelete,
          );

          if (deleteCheckedRows.length) {
            this.$message({
              type: "info",
              message: this.$tc("el.table.pleaseProcessTheRemoval"), // 삭제 체크 된 데이터가 있습니다.
            });
            return;
          }

          const changedRows = checkedRows.filter(
            (row) => row.manipulationType === "U",
          );

          if (changedRows.length) {
            this.$message({
              type: "warning",
              message: this.$tc("el.table.pleaseProcessTheUpdation"), // 변경 된 데이터가 있습니다.
            });
            return;
          }

          loading = this.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          const userInfo = this.$store.getters["user/getUserInfo"];
          const { userName, employeeNumber } = userInfo;
          const {
            data: {
              payload: { documentNo, documentId },
            },
          } = await helpers.put(`/assdocument/document`, [
            {
              ...masterModel,
              manipulationType: "C",
              companyOriginalId: this.companyOriginalId,
              roweditYn: "Y",
              applyYmd: date.nowDate(),
              employeeName: userName,
              employeeNumber,
              documentTitle: this.$tc("el.table.assignDocumentTitle"),
            },
          ]);
          this.$emit("hpe-change", {
            documentId,
            documentNo,
          });

          await this.$nextTick();

          let beAssignedRows = checkedRows.filter(
            (row) => row.chkYn === "N" && row.documentNo === documentNo,
          );

          if (!beAssignedRows.length) {
            return this.$message({
              type: "warning",
              message: this.$tc("el.table.overlapEmployeeId"), // 발령등록 대상이 없습니다.
            });
          }

          let rows = [];
          let putFlag = true;

          if (putFlag) {
            for (let i in beAssignedRows) {
              const row = beAssignedRows[i];

              if (row.checkboxSelect) {
                row.checkboxSelect = "Y";
                rows.push(row);
              }
            }

            if (rows.length > 0) {
              const {
                data: { payload },
              } = await helpers.get(`/assignments/employees`, {
                companyOriginalId: this.companyOriginalId || undefined,
                hireYmdEnd: this.hireYmdEnd,
                hireYmdStart: this.hireYmdStart,
                masking: false,
              });
              beAssignedRows = this.offMask(beAssignedRows, payload).filter(
                (data) => data.manipulationType,
              );
              const res = await helpers.put(
                `/assignments/${documentId}/${this.hireYmdStart}/${this.hireYmdEnd}/employees/previous`,
                beAssignedRows,
              );
              const {
                data: { payload: p },
              } = await helpers.put(`/assdocument/${documentId}/historyfixed`, {
                documentId,
                documentNo,
                documentTitle: "?",
                applyYmd: this.$moment().format("YYYYMMDD"),
                companyOriginalId: this.companyOriginalId,
                chiefYn: checkedRows.chiefYn,
                classCode: checkedRows.classCode,
              });

              // 발령성공 시 oErrNo = '0'
              if (p.oErrNo !== "0") {
                this.$nextTick(() => {
                  this.$message({
                    type: "info",
                    message: this.$tc("msg.assignmentInComplete"),
                  });
                  this.$router.replace(
                    {
                      name: "HAS1030",
                      params: {
                        documentNo: documentNo,
                      },
                    },
                    () => {},
                    () => {},
                  );
                });
              }

              if (res.data.payload && p.oErrNo === "0") {
                this.$message({
                  type: "success",
                  message: this.$tc("el.table.saveAssignments"), // 발령등록 되었습니다.
                });
              }

              await this.$nextTick();
              this.$emit("fetch");
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          loading && loading.close();
        });
    },
    clearValue(event) {
      const value = event.target.value;
      if (value === "" || value === null) {
        this.searchParams.documentNo = "";
      }
    },
    copyPreviousYearHolidayFn() {
      const searchYear = Number(this.prevYearValue);
      const sendData = {
        // 선택한 법인 ID
        companyOriginalId: this.originalCompanyId,
        // 복사를 원하는 기준 년도
        effectiveYear: String(searchYear),
        // 휴일 데이터 조회 검색 구분 값(Y == 휴일)
        searchWord01: "Y",
      };
      this.$confirm(
        this.$tc("el.table.copyPreviousYearHolidayWarning", null, {
          baseYear: sendData.effectiveYear,
          previousYear: sendData.effectiveYear - 1,
        }),
        `${this.legend || ""}`,
        {
          type: "warning",
          confirmButtonText: this.$tc("el.table.confirmButtonText"),
          cancelButtonText: this.$tc("el.table.cancelButtonText"),
        },
      ).then(async () => {
        if (this.prevYearValue.length === 0 && this.originalCompanyId === 0) {
          this.$message({
            type: "info",
            message: this.$tc("el.table.dataSetError"), // 선택 데이터에 문제가 있습니다.
          });
          return;
        }
        this.pending = true;
        try {
          const res = await helpers.put("work/calendar/copyData", sendData);
          if (res.data.payload) {
            this.$message({
              type: "success",
              message: this.$tc("el.table.copyPreviousYearHolidaySuccess"), // 전년도 휴일자료 복사가 완료 되었습니다.
            });
            this.$emit("fetch");
          }
        } finally {
          this.pending = false;
        }
      });
    },
    copyCompanyHolidayFn() {
      const sendData = {
        // 선택한 법인 ID
        companyOriginalId: this.originalCompanyId,
        // 복사를 원하는 기준 년도
        effectiveYear: this.prevYearValue,
        // 휴일 데이터 조회 검색 구분 값(Y == 휴일)
        // 타 법인 복사 시 사용되는 타 법인 ID
        otherCompanyId: this.selectedCompanies,
        searchWord01: "Y",
      };
      this.$confirm(
        this.$tc("el.table.copyCompanyHolidayWarning"),
        `${this.legend || ""}`,
        {
          type: "warning",
          confirmButtonText: this.$tc("el.table.confirmButtonText"),
          cancelButtonText: this.$tc("el.table.cancelButtonText"),
        },
      ).then(async () => {
        if (
          this.selectedCompanies.length === 0 &&
          this.originalCompanyId === 0
        ) {
          this.$message({
            type: "info",
            message: this.$tc("el.table.dataSetError"), // 선택 데이터에 문제가 있습니다.
          });
          return;
        }
        this.pending = true;
        try {
          const res = await helpers.put("work/calendar/copyData", sendData);
          if (res.data.payload) {
            this.$message({
              type: "success",
              message: this.$tc("el.table.copyCompanyHolidaySuccess"), // 타 법인 휴일자료 복사가 완료되었습니다.
            });
            this.selectedCompanies = [];
            this.$emit("fetch");
          }
        } finally {
          this.pending = false;
        }
      });
    },
    async selectChangeHandler(selectedCompanies) {
      let selectedRow = [];
      for (let i in this.data) {
        const row = this.data[i];

        if (row.checkboxSelect) {
          // 선택 체크박스 클릭시 Y로 보낸다.
          row.checkboxSelect = "Y";
          row.selectCompanyOriginalId = selectedCompanies;
          if (!row.courseId) {
            return this.$message({
              type: "warning",
              message: this.$tc("el.table.savedCourse"), // 저장이 되지 않은 교육은 법인선택저장을 할 수 없습니다.
            });
          }
          selectedRow.push(row);
        }
      }
      if (selectedRow.length === 0) {
        return this.$message({
          type: "warning",
          message: this.$tc("el.table.selectedCourseNames"), // 과정명을 선택해주세요.
        });
      }
      if (selectedCompanies.length < 1) {
        return this.$message({
          type: "warning",
          message: this.$tc("el.table.selectedCompanies"), // 법인을 선택해주세요.
        });
      }

      const res = await helpers.put("/education/course/copy", selectedRow);
      if (res.data.payload) {
        this.$message({
          type: "success",
          message: this.lang("msg.saveSuccess"),
        });
        this.selectedCompanies = [];
        this.$parent.$parent.fetch();
      }
    },
    cancelEducation(data) {
      this.$emit("cancel-button", data);
    },
    async onEmployeeClick() {
      if (this.employeeButtonOption.companyFlag) {
        this.selectedCompanyOriginalId = this.companyOriginalId;
        this.employeeRegistrationVisible = true;
        return;
      }
      this.selectedCompanyOriginalId = null;
      await this.setCompanySelectData();
      this.isCompany = true;
    },
    selectedCompanyClose() {
      // 법인 선택 팝업 닫기
      this.isCompany = false;
    },
    selectCompany() {
      // 법인 선택
      if (this.selectedCompanyOriginalId) {
        this.selectedCompanyClose();
        this.employeeRegistrationVisible = true;
      } else {
        return this.$message({
          type: "warning",
          message: this.$tc("el.table.selectedCompanies"), // 법인을 선택해주세요.
        });
      }
    },
    employeeRegistrationClose() {
      this.employeeRegistrationVisible = false;
      this.$emit("close-employee-registration");
    },
    async setCompanySelectData() {
      /**
       * 법인 코드 목록
       */
      const {
        data: { payload },
      } = await await CommonAPI.getCodes({
        lookupType: "ORG_COMPANY_CODE",
        lookupTypeId: 50,
      });

      this.companyList = payload.map((d) => {
        let n = {};
        n.companyId = d.lookupValueId;
        n.label = d.meaning;
        n.disabled = false;
        return n;
      });

      let authCompanyType = this.program.authCompanyType;

      // 선택법인의 경우 법인 중 사용자별 권한에 따라 법인 전체 중 부분집합이 보인다.
      if (authCompanyType === AUTH_COMPANY_TYPE.SELECTED) {
        let authCompanyOriginalIds = this.program.authCompanyOriginalIds;

        this.companyList = this.companyList.filter((d) =>
          authCompanyOriginalIds.includes(d.companyId),
        );
      }

      // 소속법인인 경우 사용자정보의 companyId와 동일한 법인1개만 고른다.
      if (authCompanyType === AUTH_COMPANY_TYPE.BELONG) {
        this.companyList = [
          this.companyList.find(
            (d) => `${this.user.companyCode}` === `${d.companyId}`,
          ),
        ];
      }
      // 현재 법인 셋
      if (this.companyOriginalId) {
        this.selectedCompanyOriginalId = this.companyOriginalId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$top-height: 32px;

::v-deep.el-table {
  &__header {
    border-bottom: 1px solid $--border-color-base;
  }

  &__body {
    padding-bottom: 0 !important;
  }

  &--border {
    .el-table__prepend-wrapper {
      @include bs-shadow("small");
      border-bottom: 1px solid $--border-color-base;
      background: transparent;

      .total-count-text {
        color: $--color-text-primary;
        font-weight: $gpro-font-weight-500;
        margin: 0 16px 0 8px;
      }

      .list_num {
        margin-right: 10px;
      }

      .el-table__prepend-inner {
        &--left {
          flex: 1 1 auto;
          padding: 16px 0 16px 16px;
        }

        .prepend-left--notice {
          font-size: 11px;
          display: inline-block;
          // margin-left: 10px;
          color: #999999;
          -webkit-text-fill-color: #999999;
        }
      }
    }
  }

  &--collapsed {
    .el-table__prepend-wrapper {
      border-bottom: 0;
    }
  }

  .el-table__empty-block {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-table__pagination {
    position: relative;
    z-index: 1;
    background: $--color-white;
    padding: 16px;
  }

  .el-table__detail-mask {
    background: rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 0;
    left: 0;

    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .detail-mask__text {
      font-size: 32px;
      color: white;
    }
  }
}

::v-deep.el-table__prepend-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: center;

  .el-table__prepend-inner {
    &--left {
      .prepend-left--title {
        display: flex;
        align-items: center;
        font-weight: $gpro-font-weight-500;
        font-size: $--font-size-medium;
        word-break: keep-all;
        vertical-align: middle;
        color: $--color-text-primary;

        i {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 2px;
          background: $gpro-warm-gray-600;
        }
      }
    }

    &--right {
      display: flex;
      margin-right: auto;
      align-items: center;

      .button-group {
        line-height: normal;
        font-size: 0;
        padding: 16px 16px 16px 0;
      }
    }
  }
}

::v-deep.tableCompanies {
  width: 180px;
  margin-right: 10px;

  & .el-tag--small {
    height: 20px;
    line-height: 20px;
  }
}

.program-code {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}

.upload-excel {
  margin-left: 10px;
  display: inline-block;
}
</style>
