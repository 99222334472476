import Vue from "vue";
import { router } from "@/http";
import store from "@/store";
import { apiBaseUrl } from "../config/cloudEnv";

function getCurrentProgramId() {
  // FIXME 문제시 수정 부탁..
  let programCode = router.history.current.name || "";
  if (store.state && store.state.user.userOn) {
    return store.state.user.programs[programCode].programId;
  }
  return null;
}

const requestWrap = (url, data, config) => {
  /**
   * method GET 일때만 authChkProgramId를 전송하는 것으로 변경
   */
  // config.params = {
  //   /**
  //    * 현재 프로그램 ID 페이지별 권한 조회시 사용
  //    */
  //   authChkProgramId: getCurrentProgramId()
  // }

  config = {
    ...config,
    params: {
      ...config.params,
      authChkProgramId: getCurrentProgramId(),
    },
  };

  return Vue.auth[config.method.toLowerCase()](url, data, config).then(
    (response) => {
      return new Promise((resolve) => {
        // @TODO check for no response.data.data?
        resolve(response);
      });
    },
  );
};

export default {
  URLSearchParams(obj) {
    var params = new URLSearchParams();
    for (var [key, value] of Object.entries(obj)) params.append(key, value);

    return params;
  },
  // Standardizes errors. A place to add logging if needed.
  get(url, params = {}) {
    const config = {
      params: {
        /**
         * 현재 프로그램 ID 페이지별 권한 조회시 사용
         */
        authChkProgramId: getCurrentProgramId(),
      },
    };

    let userParameters = {};

    if (params.params) {
      userParameters = params.params;
      for (let [key, value] of Object.entries(params)) {
        if (key != "params") config[key] = value;
      }
    } else {
      userParameters = params;
    }

    config.params = Object.assign(config.params, userParameters);
    return Vue.auth.get(url, config).then((response) => {
      return new Promise((resolve) => {
        if (response && response.headers) {
          const { headers } = response;
          response.data.pagination = {
            count: headers["pagination-count"] * 1 || 0,
            limit: headers["pagination-limit"] * 1 || null,
            page: headers["pagination-page"] * 1 || null,
          };
        }
        resolve(response);
      });
    });
  },

  put(url, data = {}, config) {
    const baseConfig = {
      method: "PUT",
    };
    return new Promise((resolve, reject) => {
      requestWrap(url, data, { ...baseConfig, ...config })
        .then(resolve)
        .catch(reject);
    });
  },

  post(url, data = {}, config) {
    const baseConfig = {
      method: "POST",
    };
    return new Promise((resolve, reject) => {
      requestWrap(url, data, { ...baseConfig, ...config })
        .then(resolve)
        .catch(reject);
    });
  },

  delete(url, data = {}, config = {}) {
    const baseConfig = {
      method: "DELETE",
    };
    /**
     * 구버전 axios에서는 두번째 파라메터에 config가 들어간다. 버전업시 제거필요.
     */
    const dataConfig = {
      ...baseConfig,
      ...config,
      params: {
        ...config.params,
        authChkProgramId: getCurrentProgramId(),
      },
      data,
    };
    return new Promise((resolve, reject) => {
      requestWrap(url, dataConfig, { ...baseConfig, ...config })
        .then(resolve)
        .catch(reject);
    });
  },
  getApiBaseUrl() {
    return apiBaseUrl();
  },
};
